@import url("https://hello.myfonts.net/count/3d1be0");

@font-face {
  font-family: "Glence";
  font-weight: 1000;
  font-display: block;
  src: url("../fontes/Glence-Black.woff2") format("woff2"),
    url("../fontes/Glence-Black.woff") format("woff");
}

@font-face {
  font-family: "Glence";
  font-weight: 400;
  font-display: block;
  src: url("../fontes/Glence-Regular.woff2") format("woff2"),
    url("../fontes/Glence-Regular.woff") format("woff");
}

@font-face {
  font-family: "Glence";
  font-weight: 900;
  font-display: block;
  src: url("../fontes/Glence-Heavy.woff2") format("woff2"),
    url("../fontes/Glence-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Glence";
  font-weight: 800;
  font-display: block;
  src: url("../fontes/Glence-ExtraBold.woff2") format("woff2"),
    url("../fontes/Glence-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Glence";
  font-weight: 700;
  font-display: block;
  src: url("../fontes/Glence-Bold.woff2") format("woff2"),
    url("../fontes/Glence-Bold.woff") format("woff");
}

@font-face {
  font-family: "Glence";
  font-weight: 300;
  font-display: block;
  src: url("../fontes/Glence-Light.woff2") format("woff2"),
    url("../fontes/Glence-Light.woff") format("woff");
}

@font-face {
  font-family: "Glence";
  font-weight: 500;
  font-display: block;
  src: url("../fontes/Glence-Medium.woff2") format("woff2"),
    url("../fontes/Glence-Medium.woff") format("woff");
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Glence, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b2b2b;
}

/* - - - - - - - - - - - - - - - - - - - - - -
 *                                            |
 *                .CLASSES                    |
 *                                            |
 - - - - - - - - - - - - - - - - - - - - - - */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 27px 120px 24px 120px;
  background-color: #f2e9d5;
  height: 100px;
}

.header img {
  height: 40px;
}

.header p {
  font-size: 20px;
  margin-left: 25px;
  text-align: right;
}

.divisor {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50.01%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: opacity 1s;
  z-index: -1;
}

.divisor div:first-child {
  flex-grow: 2;
  max-height: 67%;
  background-color: #f2e9d5;
}

.divisor div:last-child {
  flex-grow: 1;
  background-color: #fcfdff;
}

.medico {
  opacity: 0;
  transition: opacity 2s;
}

.container {
  display: flex;
  flex-direction: column;
  /* min-width: 420px; */
  max-width: 420px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 1s;
}

/* <div> do botão de entrar na sala */
.container div:last-child {
  background-color: #f7f9fc;
}

.orientacao {
  display: flex;
  flex-direction: column;
}

.tela-config {
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: 0px 2px 10px #d5d5d2;
  border-radius: 5px;
}

.container .frase-boas-vindas {
  text-align: center;
  margin: 0 -40px;
  opacity: 0;
  transition: opacity 1s;
}

.container .frase-boas-vindas p {
  word-wrap: break-word;
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 16px;
}

.container .camera {
  flex-grow: 2;
  height: 316px;
  background-color: #162442;
  border-bottom: 2px solid #e7e7e7;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: relative;
}

.container .camera img {
  height: 35px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container .camera video {
  height: 100%;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.container .configuracao {
  display: flex;
  align-content: center;
  height: 50px;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
}

.container .configuracao div {
  width: 70px;
  margin-right: 33px;
}

.container .configuracao div:last-child {
  background-color: #f7f9fc;
}

.container .paciente,
.container .configuracao {
  flex-grow: 1;
  background-color: #f7f9fc;
  border-bottom: 2px solid #e7e7e7;
  padding: 18px;
}

.container .paciente {
  margin: 0;
}

.container .paciente label {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.container .paciente input {
  /* color: #B4B4B3; */
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #d5d5d2;
  margin-top: 10px;
  font-size: 17px;
  padding-left: 20px;
  box-sizing: border-box;
  height: 55px;
  width: 100%;
}

.container .paciente input:focus {
  outline: none;
}

.container .paciente input:focus::placeholder {
  color: transparent;
}

.container .tela-config > div:last-child {
  padding: 18px;
}

.container .botao-entrar {
  flex-grow: 1;
  height: 55px;
  width: 100%;
  box-shadow: 2px 2px 5px #d5d5d2;
  background-color: #b48c46;
  border-radius: 5px;
  border: none;

  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0.5;
  transition: opacity 1s;
}

.caixa-aviso {
  position: fixed;
  bottom: 27%;
  right: 50%;
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  width: 450px;
  background-color: #ee8484;
  border-radius: 5px;
  padding: 0 0 0 25px;
  font-size: 17px;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} */

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* 
 * Retirado de "How TO - Toggle Switch" 
 * (Disponível em: https://www.w3schools.com/howto/howto_css_switch.asp) 
 */

.switch {
  position: relative;
  display: inline-block;
  width: 63px;
  height: 29px;
  margin-top: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ee8484;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 35px;
}

.slider.round img {
  color: #ffffff;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider.round img.ativo {
  left: 0;
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #8ad4a2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8ad4a2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

/* - - - - - - - - - - - - - - - - - - - - - -
 *                                            |
 *                 @MEDIAS                    |
 *                                            |
 - - - - - - - - - - - - - - - - - - - - - - */

@media screen and (max-width: 320px) {
  .header {
    height: 80px;
    padding-left: 16px;
    padding-top: 24px;
  }

  .header img {
    height: 30px;
  }

  .header p {
    display: none;
  }

  .container {
    min-width: 272px !important;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .frase-boas-vindas p {
    font-size: 16px;
    margin: 0 0 16px 0;
  }

  .container .camera {
    height: 26vh;
  }

  .container .paciente input,
  .container .botao-entrar {
    font-size: 13px;
  }

  .container .paciente input,
  .container .botao-entrar {
    height: 35px;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .header {
    height: 80px;
    padding-left: 16px;
    padding-top: 24px;
  }

  .header img {
    height: 30px;
  }

  .header p {
    display: none;
  }

  .container {
    min-width: 320px !important;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .frase-boas-vindas p {
    font-size: 16px;
    margin: 0 0 16px 0;
  }

  .container .camera {
    height: 20vh;
  }

  .container .paciente input,
  .container .botao-entrar {
    font-size: 13px;
  }

  .container .paciente input,
  .container .botao-entrar {
    height: 35px;
  }
}


@media screen and (min-width: 376px) and (max-width: 425px) and (orientation: landscape) {
  .orientacao {
    flex-direction: row;
    width: 55vw;
  }

  .header img {
    height: 25px;
  }

  .container {
    align-items: center;
  }

  .container .orientacao .camera {
    height: unset;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .frase-boas-vindas p {
    font-size: 16px;
    font-weight: 400;
  }

  /* div.paciente input { */
  .container .paciente input {
    font-size: 13px;
  }

  .container .botao-entrar {
    font-size: 13px;
  }
}

@media screen and (min-width: 376px) and (max-width: 425px) {
  .header {
    height: 80px;
    padding-left: 16px;
    padding-top: 24px !important;
  }

  .header img {
    height: 30px;
  }

  .header p {
    display: none;
  }

  .container {
    min-width: 370px !important;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .frase-boas-vindas p {
    font-size: 16px;
    margin: 0 0 16px 0;
  }

  .container .camera {
    height: 20vh;
  }

  .container .paciente {
    height: fit-content;
  }

  .container .paciente input,
  .container .botao-entrar {
    font-size: 14px;
  }

  .container .paciente input,
  .container .botao-entrar {
    height: 35px;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) and (orientation: landscape) {
  .header {
    padding: 27px 22px 24px 22px;
  }

  .header p {
    font-size: 14px;
  }

  .orientacao {
    flex-direction: row;
    width: 55vw;
    box-shadow: 0px 2px 10px #d5d5d2;
  }

  .header img {
    height: 25px;
  }

  .container {
    align-items: center;
    -ms-transform: translate(-50%, -46%);
    transform: translate(-50%, -46%);
  }

  .container .orientacao .camera {
    height: unset;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .frase-boas-vindas p {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 4px 0;
  }

  .container .orientacao .tela-config {
    box-shadow: 0px 0px 0px #d5d5d2;
  }

  .container,
  .container .camera {
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: fit-content;
  }

  .container .configuracao {
    height: fit-content;
    font-size: 9px;
  }

  .container .paciente {
    height: fit-content;
  }

  .container .paciente label {
    font-size: 9px;
  }

  .container .paciente input {
    font-size: 13px;
    height: 35px;
  }

  .container .botao-entrar {
    font-size: 13px;
    height: 30px;
  }

  .switch {
    height: 25px;
  }

  .slider:before {
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .header {
    height: 80px;
    padding-left: 16px;
    padding-top: 24px;
  }

  .header p {
    display: none;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .camera {
    height: 20vh;
  }
}

@media screen and (min-width: 769px) and (max-width: 1100px) and (orientation: landscape) {
  .orientacao {
    flex-direction: row;
  }

  .header {
    padding: 27px 22px 24px 22px;
  }

  .header img {
    height: 25px;
  }

  .container {
    align-items: center;
  }

  .container .orientacao .camera {
    height: unset;
  }

  .container .frase-boas-vindas {
    margin: 0;
  }

  .container .frase-boas-vindas p {
    font-size: 14px;
    font-weight: 400;
  }

  /* div.paciente input { */
  .container .paciente input {
    font-size: 13px;
    height: 35px;
  }

  .container .botao-entrar {
    font-size: 13px;
    height: 35px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1100px) {
  .container .camera {
    height: 20vh;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1366px) {
  .container {
    max-width: 290px;
  }

  .container .frase-boas-vindas p {
    font-size: 16px;
    margin: 0 0 16px 0;
  }

  .container .camera {
    height: 186px;
  }

  .container .paciente,
  .container .configuracao {
    height: fit-content;
  }

  .container .paciente label,
  .container .configuracao {
    font-size: 9px;
  }

  .container .paciente input {
    height: 50px;
    font-size: 13px;
  }

  .container .botao-entrar {
    height: 35px;
    font-size: 13px;
  }

  .switch {
    height: 25px;
  }

  .slider:before {
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
  }

  .slider.round img {
    height: 18px;
    top: 50%;
    right: 2px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .slider.round img.ativo {
    left: 2px;
    top: 50%;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }
}